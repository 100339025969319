import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginGuard } from 'src/app/shared/guards/auto-login.guard';

import { LoginPage } from './login.page';

const routes: Routes = [
  {
    path: 'login',
    title: 'Log in',
    component: LoginPage,
    canLoad: [AutoLoginGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginPageRoutingModule {}
