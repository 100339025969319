import * as Parse from 'parse';
import { UserParse } from '../user/user.parse';

export class PooParse extends Parse.Object {
  constructor(attributes?: Parse.Attributes, options?: any) {
    super('Poo', attributes, options);
  }

  get location(): Parse.GeoPoint {
    return this.get('location');
  }

  set location(value: Parse.GeoPoint) {
    this.set('location', value);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get createdBy(): UserParse {
    return this.get('createdBy');
  }

  set createdBy(value: UserParse) {
    this.set('createdBy', value);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get size(): number {
    return this.get('size');
  }

  set size(value: number) {
    this.set('size', value);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get hasSign(): boolean {
    return this.get('hasSign');
  }

  set hasSign(value: boolean) {
    this.set('hasSign', value);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get deletedAt(): Date {
    return this.get('deletedAt');
  }

  set deletedAt(value: Date) {
    this.set('deletedAt', value);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get private(): PooPrivateParse {
    return this.get('private');
  }
}

Parse.Object.registerSubclass('Poo', PooParse);

export class PooPrivateParse extends Parse.Object {
  constructor(attributes?: Parse.Attributes, options?: any) {
    super('PooPrivate', attributes, options);
  }

  get sign(): boolean {
    return this.get('sign') === true;
  }

  // get photo(): boolean {
  //   return this.get('photo') === true;
  // }

  get poo(): PooParse {
    return this.get('poo');
  }
}

Parse.Object.registerSubclass('PooXP', PooPrivateParse);
