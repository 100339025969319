/* eslint-disable @typescript-eslint/naming-convention */
import { PermissionState } from '@capacitor/core';
import { Position } from '@capacitor/geolocation';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Error } from '../../shared/models/error.model';

export const PositionActions = createActionGroup({
  source: 'Position API',
  events: {
    'Set Position Permission Success': props<{
      positionPermission: PermissionState;
    }>(),
    'Watch Position': emptyProps(),
    'Watch Position Success': emptyProps(),
    'Watch Position Error': props<{ error: Error }>(),
    'Set Position Success': props<{ position: Position }>(),
    'Set Position Failure': props<{ error: Error }>(),
    'Set Orientation Success': props<{ alpha: number }>(),
    'Follow Position': emptyProps(),
  },
});
