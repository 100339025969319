import { createAction, props } from '@ngrx/store';
import { User, Player } from '../../user/user.model';
import { Error } from '../../shared/models/error.model';
import { Update } from '@ngrx/entity';

export const loadUser = createAction(
  '[User Page] Load User',
  props<{ id: string }>()
);

export const loadUserSuccess = createAction(
  '[User API] Load User Success',
  props<{ user: User }>()
);

export const loadUserFailure = createAction(
  '[User API] Load User Failure',
  props<{ error: Error }>()
);

export const updateUser = createAction(
  '[User Page] Update User',
  props<{ update: Update<User> }>(),
);

export const updateUserSuccess = createAction(
  '[User API] Update User Success',
  props<{ user: User }>(),
);

export const updateUserFailure = createAction(
  '[User API] Update User Failure',
  props<{ error: Error }>(),
);

export const loadPlayerSuccess = createAction(
  '[User API] Load Player Success',
  props<{ player: Player }>()
);

export const loadPlayerFailure = createAction(
  '[User API] Load Player Failure',
  props<{ error: Error }>()
);
