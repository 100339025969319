/* eslint-disable @typescript-eslint/naming-convention */
import { Update } from '@ngrx/entity';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Poo } from '../poo.model';

export const PooEditComponentActions = createActionGroup({
  source: 'Poo Edit Component',
  events: {
    'Load Poo': props<{ id: string }>(),
    'Load Poo Tags': props<{ id: string }>(),
    'Update Poo': props<{ update: Update<Poo> }>(),
    'Load All Tags': emptyProps(),
  },
});
