import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { from, fromEvent, Observable } from 'rxjs';
import { map, pluck, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ParseService } from '../shared/services/parse.service';
import { User, Player } from './user.model';
import { UserParse, PlayerParse } from './user.parse';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private parseService: ParseService,
  ) {
    (Parse as any).liveQueryServerURL = environment.back4App.liveQueryServerUrl;
  }

  getUser(userId: string): Observable<User> {
    const query: Parse.Query<UserParse> = new Parse.Query(UserParse)
      .include('player');
    return from(query.get(userId)).pipe(
      map(userParse => ParseService.serialize<User>(userParse)),
      tap(user => console.log('Got user', user)),
    );
  }

  updateUser(userId: string, changes: Partial<User>): Observable<User> {
    const query = new Parse.Query(UserParse);
    return from(query.get(userId)).pipe(
      switchMap(user => from(Object.assign(user, changes).save())),
      map(userParse => ParseService.serialize<User>(userParse)),
      tap(user => console.log('Updated user', user)),
    );
  }

  getPlayer(playerId: string): Observable<Player> {
    const query: Parse.Query<PlayerParse> = new Parse.Query(PlayerParse);
    return from(query.get(playerId)).pipe(
      map(playerParse => ParseService.serialize<Player>(playerParse)),
      tap(player => console.log('Got player', player)),
    );
  }

  playerLiveUpdated(playerId: string): Observable<Player> {
    return this.subscribeToLiveQuery(playerId).pipe(
      switchMap(subscription => fromEvent<[PlayerParse, PlayerParse, object]>(subscription, 'update')),
      tap(console.log),
      // fromEvent somehow returns extra objects
      pluck(0),
      map(playerParse => ParseService.serialize<Player>(playerParse)),
      tap(player => console.log('Player live updated', player)),
    );
  }

  private subscribeToLiveQuery(playerId: string): Observable<Parse.LiveQuerySubscription> {
    const query: Parse.Query<PlayerParse> = new Parse.Query(PlayerParse)
      .equalTo('objectId', playerId);
      // .select('totalXP', 'level', 'levelXP', 'totalLevelXP');
    return from(query.subscribe());
  }
}
