import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { MenuPageActions } from 'src/app/menu/menu.page.actions';
import { UserService } from '../../user/user.service';
import { userUpdated } from '../auth/auth.actions';
import { selectLoggedInPlayer } from '../selectors';
import * as userActions from './user.actions';

@Injectable()
export class UserEffects {
  userUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userUpdated),
      filter(({ user }) => user !== null),
      map(({ user: { id } }) => userActions.loadUser({ id }))
    )
  );

  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuPageActions.loadUser, userActions.loadUser),
      concatMap(({ id }) =>
        this.userService.getUser(id).pipe(
          map((user) => userActions.loadUserSuccess({ user })),
          catchError((err) =>
            of(userActions.loadUserFailure({ error: Object.assign({}, err) }))
          )
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateUser),
      concatMap(({ update }) =>
        this.userService.updateUser(update.id as string, update.changes).pipe(
          map((user) => userActions.updateUserSuccess({ user })),
          catchError((err) =>
            of(userActions.updateUserFailure({ error: Object.assign({}, err) }))
          )
        )
      )
    )
  );

  updateUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.updateUserSuccess),
        tap(({ user }) => this.navCtrl.navigateForward('/profile'))
      ),
    {
      dispatch: false,
    }
  );

  // loadPlayer$ = createEffect(() => this.actions$.pipe(
  //   ofType(
  //     addPooSuccess,
  //     // TODO: add more actions that change player's XP
  //   ),
  //   switchMap(({ poo }) => this.userService.getPlayer(poo.createdBy.player.id).pipe(
  //     map(player => loadPlayerSuccess({ player })),
  //     catchError(err => of(loadPlayerFailure({ error: Object.assign({}, err) }))),
  //   ))
  // ));

  playerLiveUpdated$ = createEffect(() =>
    this.store.select(selectLoggedInPlayer).pipe(
      filter((player) => player !== null),
      switchMap((player) =>
        this.userService.playerLiveUpdated(player.id).pipe(
          map((playerLiveUpdated) =>
            userActions.loadPlayerSuccess({ player: playerLiveUpdated })
          )
          // TODO: catchError?
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private store: Store,
    private navCtrl: NavController
  ) {}
}
