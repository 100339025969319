import { createAction, props } from '@ngrx/store';
import { Credentials, User } from '../../user/user.model';
import { Error } from '../../shared/models/error.model';

export const signUp = createAction(
  '[Auth] Sign Up',
  props<{ credentials: Credentials; username: string }>(),
);

export const signUpSuccess = createAction(
  '[Auth] Sign Up Success',
  props<{ user: User }>(),
);

export const signUpFailure = createAction(
  '[Auth] Sign Up Failure',
  props<{ error: Error }>(),
);

export const requestEmailVerification = createAction(
  '[Auth] Request Email Verification',
  props<{ email: string }>(),
);

export const logIn = createAction(
  '[Auth] Log In',
  props<{ credentials: Credentials }>(),
);

export const logInSuccess = createAction(
  '[Auth] Log In Success',
  props<{ user: User }>(),
);

export const logInFailure = createAction(
  '[Auth] Log In Failure',
  props<{ error: Error }>(),
);

export const logOut = createAction(
  '[Auth] Log Out',
);

export const logOutSuccess = createAction(
  '[Auth] Log Out Success',
);

export const requestPasswordReset = createAction(
  '[Auth] Request Password Reset',
  props<{ email: string }>(),
);

export const userUpdated = createAction(
  '[Auth] User Updated',
  props<{ user: User | null }>(),
);
