import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { MapPageActions } from 'src/app/map/map.page.actions';
import { PooComponentActions } from 'src/app/poo/poo.component.actions';
import {
  Coordinates,
  FOLLOW_ZOOM,
  Layer,
  livePoosLayerId,
  PITCH,
  stalePoosLayerId,
  View,
} from '../../map/map.model';
import { CallState, LoadingState } from '../helpers';
import { PooActions } from '../poo/poo.actions';
import { PositionActions } from '../position/position.actions';
import { MapActions } from './map.actions';

export const mapFeatureKey = 'map';

export interface MapState {
  loadMapState: CallState;

  center: Coordinates;
  easeToView: View | null;

  // layers
  // layers: EntityState<Layer>;
  visibleLayerIds: string[];

  // user geolocation
  isFollowingPosition: boolean;
  isFollowingOrientation: boolean;

  // TODO: revise
  areMarkersRendered: boolean;
  isAddingMarker: boolean;
}

export const mapLayerAdapter: EntityAdapter<Layer> =
  createEntityAdapter<Layer>();

export const initialState: MapState = {
  loadMapState: LoadingState.init,

  center: { latitude: 0, longitude: 0 },
  easeToView: null,

  // layers
  // layers: {
  //   ids: poosLayerIds,
  //   entities: {
  //     [livePoosLayerId]: livePoosLayer,
  //     [stalePoosLayerId]: stalePoosLayer,
  //   },
  // },
  visibleLayerIds: [livePoosLayerId, stalePoosLayerId],

  // user geolocation
  isFollowingPosition: false,
  isFollowingOrientation: false,

  // TODO: revise
  areMarkersRendered: false,
  isAddingMarker: false,
};

export const reducer = createReducer(
  initialState,

  on(
    MapPageActions.init,
    (state): MapState => ({
      ...state,
      loadMapState: LoadingState.loading,
    })
  ),
  on(
    MapPageActions.loadMapSuccess,
    (state): MapState => ({
      ...state,
      loadMapState: LoadingState.loaded,
    })
  ),

  on(
    MapPageActions.setCenter,
    (state, { center }): MapState => ({ ...state, center })
  ),
  on(
    MapActions.easeToView,
    PooComponentActions.easeToMapView,
    (state, { view }): MapState => ({
      ...state,
      easeToView: { ...state.easeToView, ...view },
    })
  ),
  on(
    MapActions.clearEaseToView,
    (state): MapState => ({ ...state, easeToView: null })
  ),

  on(
    MapActions.followPositionSuccess,
    (state, { coords }): MapState => ({
      ...state,
      isFollowingPosition: true,
      easeToView: { ...state.easeToView, center: coords, zoom: FOLLOW_ZOOM },
    })
  ),
  on(
    MapPageActions.unfollowPosition,
    (state): MapState => ({
      ...state,
      isFollowingPosition: false,
    })
  ),

  on(
    MapActions.followOrientationSuccess,
    (state, { bearing }): MapState => ({
      ...state,
      isFollowingOrientation: true,
      easeToView: { ...state.easeToView, bearing, pitch: PITCH },
    })
  ),
  on(
    MapPageActions.unfollowOrientation,
    (state): MapState => ({
      ...state,
      isFollowingOrientation: false,
    })
  ),
  on(
    MapPageActions.resetNorth,
    (state): MapState => ({
      ...state,
      isFollowingOrientation: false,
      easeToView: { ...state.easeToView, bearing: 0, pitch: 0 },
    })
  ),

  on(
    PositionActions.setPositionSuccess,
    (state, { position: { coords: center } }): MapState =>
      state.isFollowingPosition
        ? { ...state, easeToView: { ...state.easeToView, center } }
        : state
  ),
  on(
    PositionActions.setOrientationSuccess,
    (state, { alpha }): MapState =>
      state.isFollowingPosition && state.isFollowingOrientation
        ? { ...state, easeToView: { ...state.easeToView, bearing: -alpha } }
        : state
  ),

  on(
    MapPageActions.toggleAddingMarker,
    (state): MapState => ({
      ...state,
      isAddingMarker: !state.isAddingMarker,
    })
  ),

  on(
    PooActions.addPooSuccess,
    PooActions.addPooFailure,
    (state): MapState => ({
      ...state,
      isAddingMarker: false,
    })
  ),

  on(MapPageActions.toggleStalePoos, (state): MapState => {
    const visibleLayerIds = state.visibleLayerIds.slice();
    if (visibleLayerIds.includes(stalePoosLayerId)) {
      visibleLayerIds.splice(visibleLayerIds.indexOf(stalePoosLayerId), 1);
    } else {
      visibleLayerIds.push(stalePoosLayerId);
    }
    return { ...state, visibleLayerIds };
  })
);

export const mapFeature = createFeature({
  name: mapFeatureKey,
  reducer,
});
