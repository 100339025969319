import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fromEvent } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  tap,
  throttleTime,
} from 'rxjs/operators';
import { MapPageActions } from 'src/app/map/map.page.actions';
import { PositionService } from 'src/app/map/position.service';
import { showErrorToast } from '../helpers';
import { PositionActions } from './position.actions';
import { selectStartedFollowingPosition } from './position.selectors';

@Injectable()
export class PositionEffects {
  watchIdChanged$ = createEffect(() =>
    this.positionService.watchId$.pipe(
      // map((watchId) =>
      //   watchId
      //     ? PositionActions.watchPositionSuccess({ watchId })
      //     : PositionActions.watchPositionError({ watchId })
      // )
      map(() => PositionActions.watchPositionSuccess())
      // catchError((error) => of(PositionActions.watchPositionError({ error })))
    )
  );

  positionPermissionChanged$ = createEffect(() =>
    this.positionService.positionPermission$.pipe(
      map((positionPermission) =>
        PositionActions.setPositionPermissionSuccess({ positionPermission })
      )
    )
  );

  positionChanged$ = createEffect(() =>
    this.positionService.position$.pipe(
      // TODO: put here calls to track position over time

      // wait until the position changes
      distinctUntilChanged(PositionService.comparePositions),
      // filter(position => !!position),
      map(
        ({
          coords: {
            latitude,
            longitude,
            accuracy,
            altitude,
            altitudeAccuracy,
            speed,
            heading,
          },
          timestamp,
        }) =>
          // this.zone.run(() =>
          // TODO: serialize properly
          PositionActions.setPositionSuccess({
            position: {
              coords: {
                latitude,
                longitude,
                accuracy,
                altitude,
                altitudeAccuracy,
                speed,
                heading,
              },
              timestamp,
            },
          })
        // )
      )
    )
  );

  positionChangedError$ = createEffect(() =>
    this.positionService.positionError$.pipe(
      // distinctUntilChanged((x, y) => x.code === y.code),
      //   map((err) =>
      //   PositionActions.setPositionFailure({ error: Object.assign({}, err) })
      // )
      map((err) =>
        PositionActions.watchPositionError({ error: Object.assign({}, err) })
      )
    )
  );

  positionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PositionActions.setPositionFailure),
        // present a toast if the error exists
        tap(({ error }) => showErrorToast(this.toastCtrl, error))
      ),
    {
      dispatch: false,
    }
  );

  watchPosition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MapPageActions.watchPosition, PositionActions.watchPosition),
        tap(() => this.positionService.watchPosition())
      ),
    {
      dispatch: false,
    }
  );

  followPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PositionActions.setPositionSuccess),
      concatLatestFrom(() => this.store.select(selectStartedFollowingPosition)),
      filter(([, startedFollowingPosition]) => !startedFollowingPosition),
      map(() => PositionActions.followPosition())
    )
  );

  orientationChanged$ = createEffect(() =>
    fromEvent<DeviceOrientationEvent>(window, 'deviceorientationabsolute').pipe(
      filter(({ alpha }) => alpha !== null),
      // wait until the orientation changes
      distinctUntilChanged(
        (prev, curr) => Math.abs(curr.alpha - prev.alpha) < 1
      ),
      map(({ alpha }) => PositionActions.setOrientationSuccess({ alpha })),
      // emit at most once per 0.3 s
      throttleTime(100)
    )
  );

  constructor(
    private actions$: Actions,
    private positionService: PositionService,
    private store: Store,
    private toastCtrl: ToastController // private zone: NgZone
  ) {}
}
