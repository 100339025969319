// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  back4App: {
    // appId: 'test',
    // jsKey: 'test',
    // serverUrl: 'http://localhost:1337/parse',
    // liveQueryServerUrl: 'ws://localhost:1337',
    appId: '373NDqJPw53aGu9pLZNzfbH8eJq9QqmPkC10DNpJ',
    jsKey: 'PpL5IIXtzbdnQ2srzBtXQvIDQUIqEYjTE22JR0s0',
    serverUrl: 'https://cleansole-dev.b4a.io',
    liveQueryServerUrl: 'wss://cleansole-dev.b4a.io',
  },
  mapbox: {
    // style: 'mapbox://styles/mapbox/streets-v11',
    style: 'mapbox://styles/ficinator/clg7xy8ad002601usep03ebrs/draft',
    accessToken:
      'pk.eyJ1IjoiZmljaW5hdG9yIiwiYSI6ImNqbnJ4NmJmdzBkMXUzcXMxd2oybG9jazEifQ.SWy0XOT7s9aNd_WHUhF48w',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
