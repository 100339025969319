import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
} from 'rxjs/operators';
import * as MapHelpers from 'src/app/map/map.helpers';
import { MapPageActions } from 'src/app/map/map.page.actions';
import { RELOAD_POOS_THRESHOLD_M } from 'src/app/poo/poo.model';
import { PositionActions } from '../position/position.actions';
import * as PositionSelectors from './../position/position.selectors';
import { MapActions } from './map.actions';
import { selectCenter, selectVisibleLayerIds } from './map.selectors';

@Injectable()
export class MapEffects {
  clearEaseToView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapPageActions.setCenter),
      debounceTime(300),
      map(() => MapActions.clearEaseToView())
    )
  );

  loadNearPoos$ = createEffect(() =>
    this.store.select(selectCenter).pipe(
      // center must be defined
      filter((center) => !!center),
      // wait for a second before firing the request
      debounceTime(1000),
      // reload near poos only if the map center moves more than 10 meters
      // TODO: solve the problem with walking (move less than 10 meters)
      distinctUntilChanged((x, y) =>
        MapHelpers.areCoordinatesClose(x, y, RELOAD_POOS_THRESHOLD_M)
      ),
      concatLatestFrom(() => this.store.select(selectVisibleLayerIds)),
      map(([center, visibleLayerIds]) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const filter = visibleLayerIds.map((layerId) =>
          layerId.replace('poos-', '')
        );
        return MapActions.loadNearPoos({ coordinates: center, filter });
      })
    )
  );

  followPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapPageActions.followPosition, PositionActions.followPosition),
      switchMap(() =>
        this.store.select(PositionSelectors.selectPosition).pipe(
          first((position) => !!position),
          map(({ coords }) => MapActions.followPositionSuccess({ coords }))
        )
      )
    )
  );

  followOrientation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapPageActions.followOrientation),
      switchMap(() =>
        this.store.select(PositionSelectors.selectBearing).pipe(
          first(),
          map((bearing) => MapActions.followOrientationSuccess({ bearing }))
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store) {}
}
