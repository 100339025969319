import { Position } from '@capacitor/geolocation';
import { createSelector } from '@ngrx/store';
import * as mapboxgl from 'mapbox-gl';
import { Error } from '../../shared/models/error.model';
import { CallState, getError, LoadingState } from '../helpers';
import { positionFeature } from './position.reducer';

export const {
  selectPositionState,
  selectPosition,
  selectAlpha,
  selectWatchPositionState,
  selectStartedFollowingPosition,
} = positionFeature;

export const selectPositionLngLat = createSelector(
  selectPosition,
  (position: Position | null): mapboxgl.LngLat | undefined => {
    if (!position) {
      return undefined;
    }
    const {
      coords: { latitude, longitude },
    } = position;
    return new mapboxgl.LngLat(longitude, latitude);
  }
);

export const selectIsWatchingPosition = createSelector(
  selectWatchPositionState,
  (watchPositionState: CallState): boolean =>
    watchPositionState === LoadingState.loaded
);

export const selectWatchPositionError = createSelector(
  selectWatchPositionState,
  (watchPositionState: CallState): Error | null => getError(watchPositionState)
);

export const selectIsPositionDenied = createSelector(
  selectWatchPositionError,
  (watchPositionError: Error | null): boolean => watchPositionError !== null
);

export const selectBearing = createSelector(
  selectAlpha,
  (alpha: number): number => -alpha
);

export const selectPositionView = createSelector(
  selectPosition,
  selectBearing,
  (position, bearing) => ({ center: position?.coords, bearing })
);
