import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginPageModule } from './login/login.module';
import { SignUpPageRoutingModule } from './sign-up/sign-up-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LoginPageModule,
    SignUpPageRoutingModule,
    AuthRoutingModule,
  ],
})
export class AuthModule {}
