import { distance } from '@turf/turf';
import * as mapboxgl from 'mapbox-gl';
import { Coordinates } from './map.model';

export const areCoordinatesEqual = (a: Coordinates, b: Coordinates): boolean =>
  a.latitude === b.latitude && a.longitude === b.longitude;

export const areCoordinatesClose = (
  a: Coordinates,
  b: Coordinates,
  threshold: number = 0.01
): boolean => {
  const d = distance([a.longitude, a.latitude], [b.longitude, b.latitude], {
    units: 'meters',
  });
  return d <= threshold;
};

export const lngLat2GeoJsonFeature = (
  lngLat: mapboxgl.LngLat
): GeoJSON.Feature => ({
  type: 'Feature',
  geometry: {
    type: 'Point',
    coordinates: lngLat.toArray(),
  },
  properties: {},
});

export const areLngLatsEqual = (
  a: mapboxgl.LngLat,
  b: mapboxgl.LngLat
): boolean => a.lng === b.lng && a.lng === b.lng;

export const areMapViewsEqual = (
  a: mapboxgl.EaseToOptions,
  b: mapboxgl.EaseToOptions
): boolean =>
  areLngLatsEqual(a.center as mapboxgl.LngLat, b.center as mapboxgl.LngLat) &&
  a.zoom === b.zoom &&
  a.bearing === b.bearing &&
  a.pitch === b.pitch;
