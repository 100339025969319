import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { LoginPage } from '../../auth/login/login.page';
import { selectIsUserLoggedIn } from '../../state/auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    // private navCtrl: NavController,
    private modalCtrl: ModalController,
    private store: Store
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.isUserLoggedIn();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.isUserLoggedIn();
  }

  private isUserLoggedIn(): Observable<boolean> {
    return this.store.select(selectIsUserLoggedIn).pipe(
      take(1),
      tap((isUserLoggedIn) => {
        if (!isUserLoggedIn) {
          // // forward to login
          // this.navCtrl.navigateForward('/login');
          // open login modal
          this.modalCtrl
            .create({
              component: LoginPage,
            })
            .then((modal) => modal.present());
        }
      })
    );
  }
}
