import { createFeature, createReducer, on } from '@ngrx/store';
import { CallState, LoadingState } from '../helpers';
import {
  logIn,
  logInFailure,
  logInSuccess,
  signUp,
  signUpFailure,
  signUpSuccess,
  userUpdated,
} from './auth.actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  loggedInUserId: string | null;

  signUpState: CallState;
  logInState: CallState;
}

export const initialState: AuthState = {
  loggedInUserId: null,

  signUpState: LoadingState.init,
  logInState: LoadingState.init,
};

export const reducer = createReducer(
  initialState,

  on(
    signUp,
    (state, { credentials, username }): AuthState => ({
      ...state,
      signUpState: LoadingState.loading,
    })
  ),
  on(
    signUpSuccess,
    (state, { user }): AuthState => ({
      ...state,
      signUpState: LoadingState.loaded,
    })
  ),
  on(
    signUpFailure,
    (state, { error }): AuthState => ({
      ...state,
      signUpState: { error },
    })
  ),

  on(
    logIn,
    (state, { credentials }): AuthState => ({
      ...state,
      logInState: LoadingState.loading,
    })
  ),
  on(
    logInSuccess,
    (state, { user }): AuthState => ({
      ...state,
      logInState: LoadingState.loaded,
    })
  ),
  on(
    logInFailure,
    (state, { error }): AuthState => ({ ...state, logInState: { error } })
  ),

  on(
    userUpdated,
    (state, { user }): AuthState => ({
      ...state,
      loggedInUserId: user ? user.id : null,
    })
  )
);

export const authFeature = createFeature({
  name: authFeatureKey,
  reducer,
});
