export const INITIAL_ZOOM = 10;
export const MAX_ZOOM = 19;
export const FOLLOW_ZOOM = 18;
export const PITCH = 60;

export interface Coordinates {
  latitude: number;
  longitude: number;

  // equal(other: Coordinates): boolean;
}

export interface FollowPositionStatus {
  isFollowingPosition: boolean;
  isFollowingOrientation: boolean;
}

export interface View {
  center?: Coordinates;
  zoom?: number;
  bearing?: number;
  pitch?: number;
}

export interface Layer {
  id: string;
  name: string;
  isVisible: boolean;
}

export const livePoosLayerId = 'poos-live';
export const stalePoosLayerId = 'poos-stale';
export const poosLayerIds = [livePoosLayerId, stalePoosLayerId];

export const livePoosLayer: Layer = {
  id: livePoosLayerId,
  name: 'live',
  isVisible: true,
};

export const stalePoosLayer: Layer = {
  id: stalePoosLayerId,
  name: 'stale',
  isVisible: false,
};
