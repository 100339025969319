/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, props } from '@ngrx/store';
import { View } from '../map/map.model';

export const PooComponentActions = createActionGroup({
  source: 'Poo Component',
  events: {
    'Load Poo': props<{ id: string }>(),
    'Load Poo Tags': props<{ id: string }>(),
    'Ease To Map View': props<{ view: View }>(),
    'Delete Poo': props<{ id: string }>(),
  },
});
