import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { mapFeature } from './map.reducer';
import { EffectsModule } from '@ngrx/effects';
import { MapEffects } from './map.effects';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(mapFeature),
    EffectsModule.forFeature([MapEffects]),
  ]
})
export class MapStateModule { }
