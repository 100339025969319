import * as Parse from 'parse';

export class UserParse extends Parse.User {
  constructor(attributes?: Parse.Attributes) {
    super(attributes);
  }

  get email(): string {
    return this.getEmail();
  }

  set email(value: string) {
    this.setEmail(value);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get username(): string {
    return this.getUsername();
  }

  set username(value: string) {
    this.setUsername(value);
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get emailVerified(): boolean {
    return this.get('emailVerified') === true;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get player(): PlayerParse {
    return this.get('player');
  }
}

Parse.Object.registerSubclass('_User', UserParse);

export class PlayerParse extends Parse.Object {
  constructor(attributes?: Parse.Attributes, options?: any) {
    super('Player', attributes, options);
  }

  get totalXP(): number {
    return this.get('totalXP');
  }

  get level(): number {
    return this.get('level');
  }

  get levelXP(): number {
    return this.get('levelXP');
  }

  get totalLevelXP(): number {
    return this.get('totalLevelXP');
  }

  get user(): UserParse {
    return this.get('user');
  }
}

Parse.Object.registerSubclass('Player', PlayerParse);
