import { Injectable, OnDestroy } from '@angular/core';
import { PermissionState } from '@capacitor/core';
import { CallbackID, Geolocation, Position } from '@capacitor/geolocation';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Error } from '../shared/models/error.model';

@Injectable({
  providedIn: 'root',
})
export class PositionService implements OnDestroy {
  static readonly positionOptions: PositionOptions = {
    enableHighAccuracy: true,
  };

  // coordinates$: Observable<Coordinates> = of(
  //   { latitude: 1, longitude: 1 },
  //   { latitude: 2, longitude: 2 },
  //   { latitude: 3, longitude: 3 },
  // ).pipe(
  //   delayWhen(coordinates => timer(coordinates.latitude * 1000)),
  // );

  private positionPermissionSubject$: Subject<PermissionState> =
    new Subject<PermissionState>();
  private watchIdSubject$: ReplaySubject<CallbackID | undefined> =
    new ReplaySubject<CallbackID | undefined>(1);
  private positionSubject$: Subject<Position> = new Subject<Position>();
  private positionErrorSubject$: Subject<GeolocationPositionError> =
    new Subject<GeolocationPositionError>();

  constructor() {
    this.isPermissionGranted().then(
      (isPermissionGranted) => isPermissionGranted && this.watchPosition()
    );
    // this.getPermissionState().then(
    //   (permissionState) => permissionState !== 'denied' && this.watchPosition()
    // );
  }

  get positionPermission$(): Observable<PermissionState> {
    return this.positionPermissionSubject$.asObservable();
  }

  get watchId$(): Observable<CallbackID | undefined> {
    return this.watchIdSubject$.asObservable();
  }

  get position$(): Observable<Position> {
    return this.positionSubject$.asObservable();
  }

  get positionError$(): Observable<Error | null> {
    return this.positionErrorSubject$
      .asObservable()
      .pipe(
        map((error) =>
          error ? { code: error.code, message: error.message } : null
        )
      );
  }

  static comparePositions(p1: Position, p2: Position): boolean {
    const c1 = p1.coords;
    const c2 = p2.coords;
    // TODO: allow close locations to be the same
    return (
      c1.latitude === c2.latitude &&
      c1.longitude === c2.longitude &&
      c1.accuracy === c2.accuracy
    );
  }

  // async watchPositionPermission(): Promise<void> {
  //   // works for web
  //   const permissionStatus = await navigator.permissions.query({
  //     name: 'geolocation',
  //   });
  //   permissionStatus.onchange = () =>
  //     this.positionPermissionSubject$.next(permissionStatus.state);
  // }

  // async requestPermission(): Promise<void> {
  //   // works only on mobile
  //   const permissionStatus = await Geolocation.requestPermissions({
  //     permissions: ['location'],
  //   });
  //   this.positionPermissionSubject$.next(permissionStatus.location);
  // }

  // async checkPermission(): Promise<void> {
  //   const permissions = await Geolocation.checkPermissions();
  //   this.positionPermissionSubject$.next(permissions.location);
  // }

  async watchPosition(): Promise<void> {
    const callbackId: CallbackID = await Geolocation.watchPosition(
      PositionService.positionOptions,
      (position: Position | null, err?: any): void => {
        if (err) {
          console.error('Error getting current position', err);
          // this.positionErrorSubject$.next(err);

          if (
            // denied from web
            err.code === GeolocationPositionError.PERMISSION_DENIED ||
            // permission denied from mobile
            err.message === 'Location permission was denied'
          ) {
            console.log(err);
            this.clearWatch();
            this.positionErrorSubject$.next(err);
            // this.watchIdSubject$.error(err);
          }
        } else {
          // console.log('Got current position', position);
          this.positionSubject$.next(position);
        }
      }
    );
    console.log('Started watching position with callback id', callbackId);
    // this.positionPermissionSubject$.next('granted');
    this.watchIdSubject$.next(callbackId);
  }

  ngOnDestroy(): void {
    this.clearWatch();
  }

  private async getPermissionState(): Promise<PermissionState> {
    try {
      // mobile only
      const permissionStatus = await Geolocation.requestPermissions({
        permissions: ['location'],
      });
      return permissionStatus.location;
    } catch (err) {
      // web only
      const permissionStatus = await navigator.permissions.query({
        name: 'geolocation',
      });
      return permissionStatus.state;
    }
  }

  private async isPermissionGranted(): Promise<boolean> {
    const permissionState = await this.getPermissionState();
    return permissionState === 'granted';
  }

  private clearWatch(): void {
    this.watchId$
      .pipe(
        take(1),
        filter((id) => id !== undefined)
      )
      .subscribe(async (id) => {
        await Geolocation.clearWatch({ id });
        console.log('Stopped watching position with callback id', id);
        this.watchIdSubject$.next(undefined);
      });
  }

  // watchPosition(): Observable<Position> {
  //   return new Observable((subscriber: Subscriber<Position>): TeardownLogic => {
  //     let callbackId: CallbackID;
  //     from(Geolocation.watchPosition(
  //       PositionService.POSITION_OPTIONS,
  //       (position: Position | null, err?: any): void => {
  //         if (position) {
  //           console.log('Got current position', position);
  //           subscriber.next(position);
  //         } else {
  //           console.error('Error getting current position', err);
  //           // TODO: handle different errors
  //           subscriber.error(err);
  //           this.toastCtrl.create({
  //             message: err.message,
  //             duration: 1000,
  //           }).then(toast => toast.present());
  //         }
  //       }
  //     )).subscribe(id => {
  //       console.log('Started watching location with callback id', id);
  //       // this is probably wrong way of passing callback id
  //       callbackId = id;
  //     });

  //     return () => {
  //       if (callbackId === undefined) {
  //         return;
  //       }

  //       from(Geolocation.clearWatch({
  //         id: callbackId,
  //       })).subscribe(() => {
  //         console.log('Stopped watching location with callback id', callbackId);
  //       });
  //     }
  //   }).pipe(
  //     // share stream for other subscribers
  //     // keep the last position in memory for late subscribers
  //     shareReplay(1),
  //   );
  // }

  // watchLocation(): Observable<Position> {
  //   // return this.checkPermission().pipe(
  //   //   switchMap(hasPermission => hasPermission ? of('granted') : this.requestPermission()),
  //   //   filter(permissionState => permissionState === 'granted'),
  //   //   switchMap(() =>
  //   return bindCallback(Geolocation.watchPosition)(
  //     LocationService.POSITION_OPTIONS,
  //   ).pipe(
  //     // the callback arguments differ for success and error:
  //     // * success: single argument position => pass it further
  //     // * error: two arguments possition (always null) and error => throw the error
  //     map((callbackArgs: Position | [Position | null, GeolocationPositionError]) => {
  //       console.log(callbackArgs);
  //       if (Array.isArray(callbackArgs)) {
  //         throw callbackArgs[1]
  //       };
  //       return callbackArgs;
  //     }),
  //     // shareReplay(1),
  //   );
  // }
  //
  // private onPositionSuccess(position: Position): void {
  //   console.log('Got current position', position);
  //   this._position$.next(position);
  // }

  // private onPositionError(err: GeolocationPositionError): void {
  //   console.error('Error getting current position', err);

  //   // if (err.code === GeolocationPositionError.PERMISSION_DENIED) {
  //   //   this.stopWatchLocation();
  //   // }

  //   this.checkPermission().subscribe(hasPermission => {
  //     if (!hasPermission) {
  //       this.stopWatchLocation();
  //     }
  //   });

  //   // const alert = await this.alertCtrl.create({
  //   //   message: err.message,
  //   // });
  //   // alert.present();
  //   this.toastCtrl.create({
  //     message: err.message,
  //     duration: 1000,
  //   }).then(alert => alert.present());
  // }

  // private onPositionUpdate(position: Position | null, err?: any): void {
  //   if (position) {
  //     this.onPositionSuccess(position);
  //   } else {
  //     this.onPositionError(err);
  //   }
  // }

  // stopWatchLocation(): Subscription {
  //   return this._callbackId$.pipe(
  //     // handle error if callback id is undefined
  //     tap(callbackId => {
  //       if (callbackId === undefined) {
  //         throw new Error('Cannot clear watch location callback: callback id is undefined.');
  //       }
  //     }),
  //     // _callbackId$ completes here
  //     switchMap(callbackId => from(Geolocation.clearWatch({
  //       id: callbackId,
  //     }))),
  //     tap(() => {
  //       console.log('Cleared watching location');
  //       // this._callbackId$.next(undefined);
  //       this._position$.complete();
  //     }),
  //   ).subscribe();
  // }
}
