import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { from, fromEvent, Observable } from 'rxjs';
import { map, mergeMap, pluck, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Coordinates } from '../map/map.model';
import {
  MAX_STALE_MS,
  NEAR_POOS_LIMIT,
  Poo,
  RECENT_POOS_LIMIT,
  Tag,
} from '../poo/poo.model';
import { PooParse } from '../poo/poo.parse';
import { ParseService } from '../shared/services/parse.service';
import { UserParse } from '../user/user.parse';

@Injectable({
  providedIn: 'root',
})
export class PooService {
  constructor(private parseService: ParseService) {
    (Parse as any).liveQueryServerURL = environment.back4App.liveQueryServerUrl;
  }

  pooLiveAdded(): Observable<Poo> {
    return this.subscribeToLiveQuery().pipe(
      mergeMap((subscription) =>
        fromEvent<[PooParse, object]>(subscription, 'create')
      ),
      // fromEvent somehow returns extra object
      pluck(0),
      map((pooParse) => ParseService.serialize<Poo>(pooParse))
    );
  }

  pooLiveDeleted(): Observable<Poo> {
    return this.subscribeToLiveQuery().pipe(
      mergeMap((subscription) =>
        fromEvent<[PooParse, PooParse, object]>(subscription, 'leave')
      ),
      // fromEvent somehow returns extra objects
      pluck(0),
      map((pooParse) => ParseService.serialize<Poo>(pooParse))
    );
  }

  getNearPoos(
    coords: Coordinates,
    filter: string[] = [],
    limit: number = NEAR_POOS_LIMIT
  ): Observable<Poo[]> {
    const query = new Parse.Query(PooParse)
      // TODO: really delete the poos
      .doesNotExist('deletedAt')
      // poos at most 3 months old
      .greaterThanOrEqualTo('updatedAt', new Date(Date.now() - MAX_STALE_MS))
      // sort by distance from the map center
      .near('location', new Parse.GeoPoint(coords))
      // get only nearest 100
      .limit(limit);
    return from(query.find()).pipe(
      map((poosParse) =>
        poosParse.map((pooParse) => ParseService.serialize<Poo>(pooParse))
      ),
      tap((poos) => console.log('Got near poos', poos))
    );
  }

  addPoo(coords: Coordinates): Observable<Poo> {
    const poo = new PooParse({
      location: new Parse.GeoPoint(coords),
    });

    return from(poo.save()).pipe(
      map((pooParse) => ParseService.serialize<Poo>(pooParse)),
      tap((pooCreated) => console.log('Added poo', pooCreated))
    );
  }

  getPoo(pooId: string): Observable<Poo> {
    const query = new Parse.Query(PooParse)
      // .equalTo('objectId', pooId)
      .include('private');
    return from(query.get(pooId)).pipe(
      map((pooParse) => ParseService.serialize<Poo>(pooParse)),
      tap((poo) => console.log('Got poo', poo))
    );
  }

  updatePoo(pooId: string, changes: Partial<Poo>): Observable<Poo> {
    const changesObject = {
      ...changes,
      tags: changes.tags.map((tag) => tag.id),
    };

    return from(new Parse.Query(PooParse).get(pooId)).pipe(
      // switchMap(poo => from(Object.assign(poo, changes).save())),
      switchMap((poo) =>
        from(ParseService.update<PooParse>(poo, changesObject))
      ),
      switchMap((poo) => from(poo.save())),
      map((pooParse) => ParseService.serialize<Poo>(pooParse)),
      tap((pooUpdated) => console.log('Updated poo', pooUpdated))
    );
  }

  deletePoo(pooId: string): Observable<Poo> {
    const query = new Parse.Query(PooParse).doesNotExist('deletedAt');
    return from(query.get(pooId)).pipe(
      mergeMap((poo) => {
        poo.deletedAt = new Date();
        return from(poo.save());
      }),
      map((pooParseDeleted) => ParseService.serialize<Poo>(pooParseDeleted)),
      tap((pooDeleted) => console.log('Deleted poo', pooDeleted))
      // catchError((err: any) => {
      //   console.error('Error deleting poo', pooId, err);
      //   return of(null);
      // }),
    );
  }

  restorePoo(pooId: string): Observable<Poo> {
    const query: Parse.Query<PooParse> = new Parse.Query(PooParse);
    return from(query.get(pooId)).pipe(
      mergeMap((poo) => {
        poo.unset('deletedAt');
        return from(poo.save());
      }),
      map((pooParseRestored) => ParseService.serialize<Poo>(pooParseRestored)),
      tap((pooRestored) => console.log('Restored poo', pooRestored))
    );
  }

  getRecentPoosOfUser(
    userId: string,
    limit: number = RECENT_POOS_LIMIT
  ): Observable<Poo[]> {
    const user: UserParse = new UserParse();
    user.id = userId;
    const query = new Parse.Query(PooParse)
      // TODO: really delete the poos
      .doesNotExist('deletedAt')
      // poos at most 3 months old
      .greaterThanOrEqualTo('updatedAt', new Date(Date.now() - MAX_STALE_MS))
      .equalTo('createdBy', user)
      .limit(limit);
    return from(query.find()).pipe(
      map((poosParse) =>
        poosParse.map((pooParse) => ParseService.serialize<Poo>(pooParse))
      ),
      tap((poos) => console.log('Got poos', poos, 'for user', user))
    );
  }

  getTags(): Observable<Tag[]> {
    const query: Parse.Query = new Parse.Query('Tag');
    return from(query.find()).pipe(
      map((tags) => tags.map((tag) => ParseService.serialize<Tag>(tag))),
      tap((tags) => console.log('Got tags', tags))
    );
  }

  getPooTags(pooId: string): Observable<Tag[]> {
    const poo = new PooParse();
    poo.id = pooId;
    const query: Parse.Query = poo.relation('tags').query();
    return from(query.find()).pipe(
      map((tagsParse) =>
        tagsParse.map((tagParse) => ParseService.serialize<Tag>(tagParse))
      ),
      tap((tags) => console.log('Got tags', tags, 'for poo', pooId))
    );
  }

  private subscribeToLiveQuery(): Observable<Parse.LiveQuerySubscription> {
    const query = new Parse.Query(PooParse)
      // TODO: really delete the poos
      .doesNotExist('deletedAt')
      // poos at most 3 months old
      .greaterThanOrEqualTo('updatedAt', new Date(Date.now() - MAX_STALE_MS));
    return from(query.subscribe());
  }
}
