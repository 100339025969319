import { Injectable } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { MapPageActions } from 'src/app/map/map.page.actions';
import { MenuPageActions } from 'src/app/menu/menu.page.actions';
import { PooEditComponentActions } from 'src/app/poo/poo-edit/poo-edit.component.actions';
import { PooComponentActions } from 'src/app/poo/poo.component.actions';
import { UserPageActions } from 'src/app/user/user.page.actions';
import { PooService } from '../../poo/poo.service';
import { showErrorToast, showToast } from '../helpers';
import { MapActions } from '../map/map.actions';
import { PooActions } from './poo.actions';

@Injectable()
export class PooEffects {
  loadNearPoos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapActions.loadNearPoos),
      // switchMap cancels any requests in progress if the action is dispatched again
      switchMap(({ coordinates, filter }) =>
        this.pooService.getNearPoos(coordinates, filter).pipe(
          map((poos) => PooActions.loadPoosSuccess({ poos })),
          catchError((err) =>
            of(PooActions.loadPoosFailure({ error: Object.assign({}, err) }))
          )
        )
      )
    )
  );

  addPoo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MapPageActions.addPoo),
      concatMap(({ coordinates }) =>
        this.pooService.addPoo(coordinates).pipe(
          map((poo) => PooActions.addPooSuccess({ poo })),
          catchError((err) =>
            of(PooActions.addPooFailure({ error: Object.assign({}, err) }))
          )
        )
      )
    )
  );

  addPooSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PooActions.addPooSuccess),
        tap(({ poo }) =>
          this.navCtrl.navigateForward(['/map/poos', poo.id, 'edit'])
        )
      ),
    {
      dispatch: false,
    }
  );

  addPooLive$ = createEffect(() =>
    this.pooService
      .pooLiveAdded()
      .pipe(map((poo) => PooActions.addPooLive({ poo })))
  );

  loadPoo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PooComponentActions.loadPoo, PooEditComponentActions.loadPoo),
      concatMap(({ id }) =>
        this.pooService.getPoo(id).pipe(
          map((poo) => PooActions.loadPooSuccess({ poo })),
          catchError((err) =>
            of(PooActions.loadPooFailure({ error: Object.assign({}, err) }))
          )
        )
      )
    )
  );

  updatePoo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PooEditComponentActions.updatePoo),
      concatMap(({ update }) =>
        this.pooService.updatePoo(update.id as string, update.changes).pipe(
          map((poo) => PooActions.updatePooSuccess({ poo })),
          catchError((err) =>
            of(PooActions.updatePooFailure({ error: Object.assign({}, err) }))
          )
        )
      )
    )
  );

  updatePooSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PooActions.updatePooSuccess),
        tap(({ poo }) => this.navCtrl.navigateBack(['/map/poos', poo.id]))
      ),
    {
      dispatch: false,
    }
  );

  deletePoo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PooComponentActions.deletePoo),
      tap(({ id }) => this.navCtrl.navigateForward(['/map'])),
      concatMap(({ id }) =>
        this.pooService.deletePoo(id).pipe(
          map((poo) => PooActions.deletePooSuccess({ poo })),
          catchError((err) =>
            of(PooActions.deletePooFailure({ error: Object.assign({}, err) }))
          ),
          takeUntil(this.actions$.pipe(ofType(PooActions.restorePoo)))
        )
      )
    )
  );

  deletePooSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PooActions.deletePooSuccess),
        tap(({ poo }) => {
          const id = poo.id;
          showToast(this.toastCtrl, {
            message: `Poo ${id} successfully deleted`,
            duration: 3000,
            buttons: [
              {
                text: 'Undo',
                handler: () =>
                  this.store.dispatch(PooActions.restorePoo({ id })),
              },
            ],
          });
        })
      ),
    {
      dispatch: false,
    }
  );

  deletePooLive$ = createEffect(() =>
    this.pooService
      .pooLiveDeleted()
      .pipe(map((poo) => PooActions.deletePooLive({ poo })))
  );

  restorePoo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PooActions.restorePoo),
      concatMap(({ id }) =>
        this.pooService.restorePoo(id).pipe(
          map((poo) => PooActions.addPooSuccess({ poo }))
          // TODO: restorePooFailure
        )
      )
    )
  );

  loadPoosOfUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuPageActions.loadPoosOfUser, UserPageActions.loadPoosOfUser),
      concatMap(({ id }) =>
        this.pooService.getRecentPoosOfUser(id).pipe(
          map((poos) => PooActions.loadPoosOfUserSuccess({ poos })),
          catchError((err) =>
            of(
              PooActions.loadPoosOfUserFailure({
                error: Object.assign({}, err),
              })
            )
          )
        )
      )
    )
  );

  loadAllTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PooEditComponentActions.loadAllTags),
      concatMap(() =>
        this.pooService.getTags().pipe(
          map((tags) => PooActions.loadAllTagsSuccess({ tags })),
          catchError((err) =>
            of(PooActions.loadAllTagsFailure({ error: Object.assign({}, err) }))
          )
        )
      )
    )
  );

  loadPooTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PooComponentActions.loadPooTags,
        PooEditComponentActions.loadPooTags
      ),
      concatMap(({ id }) =>
        this.pooService.getPooTags(id).pipe(
          map((tags) =>
            PooActions.loadPooTagsSuccess({ update: { id, changes: { tags } } })
          ),
          catchError((err) =>
            of(PooActions.loadPooTagsFailure({ error: Object.assign({}, err) }))
          )
        )
      )
    )
  );

  pooFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PooActions.addPooFailure, PooActions.deletePooFailure),
        // present a toast if the error exists
        tap(({ error }) => showErrorToast(this.toastCtrl, error))
      ),
    {
      dispatch: false,
    }
  );

  constructor(
    private actions$: Actions,
    private pooService: PooService,
    private store: Store,
    private navCtrl: NavController,
    private toastCtrl: ToastController
  ) {}
}
