import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PositionEffects } from './position.effects';
import { positionFeature } from './position.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([PositionEffects]),
    StoreModule.forFeature(positionFeature),
  ],
})
export class PositionStateModule {}
