import { ParseObject } from '../shared/models/parse-object.model';

export const USERNAME_MIN_LENGTH = 2;
export const PASSWORD_MIN_LENGTH = 5;

export interface Credentials {
  email: string;
  password: string;
}

export interface User extends ParseObject {
  email: string;
  username: string;
  emailVerified: boolean;
  player: Player;
}

export interface Player extends ParseObject {
  totalXP: number;
  level: number;
  levelXP: number;
  totalLevelXP: number;
  user: User;
}
