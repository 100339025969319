import { MAX_STALE_MS, MIN_STALE_MS, Poo } from './poo.model';

export class PooHelpers {
  static isPooStale(poo: Poo): boolean {
    const diffMs = Date.now() - Date.parse(poo.updatedAt);
    return diffMs > MIN_STALE_MS && diffMs <= MAX_STALE_MS;
  }

  /**
   * Transform the list of poos into geojson.
   *
   * @param poos list of poo objects
   * @returns poos in geojson data for the map source
   */
  static poos2GeoJson(poos: Poo[]): GeoJSON.FeatureCollection {
    return {
      type: 'FeatureCollection',
      features: poos.map((poo) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [poo.location.longitude, poo.location.latitude],
        },
        properties: {
          id: poo.id,
          isStale: PooHelpers.isPooStale(poo),
        },
      })),
    };
  }
}
