import { Params } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Error } from '../../shared/models/error.model';
import { User } from '../../user/user.model';
import { CallState, getError, LoadingState } from '../helpers';
import { selectRouteParams } from '../router/router.selectors';
import { adapter, userFeature } from './user.reducer';

export const { selectAll } = adapter.getSelectors();

export const {
  selectUserState,
  selectIds,
  selectEntities,
  selectLoadUserState,
  selectUpdateUserState,
} = userFeature;

export const selectUserEntities = selectEntities;

export const selectSelectedUserId = createSelector(
  selectRouteParams,
  (params?: Params): string | null => params?.userId ?? null
);

export const selectSelectedUser = createSelector(
  selectSelectedUserId,
  selectEntities,
  (id: string | null, entities: Dictionary<User>): User | null =>
    entities[id] || null
);

export const selectIsUserLoading = createSelector(
  selectLoadUserState,
  (loadUserState: CallState): boolean => loadUserState === LoadingState.loading
);

export const selectIsUserLoaded = createSelector(
  selectLoadUserState,
  (loadUserState: CallState): boolean => loadUserState === LoadingState.loaded
);

export const selectLoadUserError = createSelector(
  selectLoadUserState,
  (loadUserState: CallState): Error | null => getError(loadUserState)
);

export const selectIsUserUpdating = createSelector(
  selectUpdateUserState,
  (updateUserState: CallState): boolean =>
    updateUserState === LoadingState.loading
);

export const selectIsUserUpdated = createSelector(
  selectUpdateUserState,
  (updateUserState: CallState): boolean =>
    updateUserState === LoadingState.loaded
);

export const selectUpdateUserError = createSelector(
  selectUpdateUserState,
  (updateUserState: CallState): Error | null => getError(updateUserState)
);
