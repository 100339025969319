import { Coordinates } from '../map/map.model';
import { ParseObject } from '../shared/models/parse-object.model';
import { User } from '../user/user.model';

export const MIN_STALE_MS = 30 * 24 * 60 * 60 * 1000;
export const MAX_STALE_MS = 91 * 24 * 60 * 60 * 1000;
export const NEAR_POOS_LIMIT = 100;
export const RECENT_POOS_LIMIT = 10;
export const RELOAD_POOS_THRESHOLD_M = 10;

export interface Tag extends ParseObject {
  name: string;
}

export interface Poo extends ParseObject {
  location: Coordinates;
  deletedAt: string;
  createdBy: User;
  size?: number;
  hasSign: boolean;
  tags: Tag[];
  private: PooPrivate;
}

export interface PooPrivate extends ParseObject {
  sign: boolean;
  // photo: boolean;
  tags: boolean;
  poo: Poo;
}
