import { Injectable } from '@angular/core';
import { Route, UrlSegment } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { selectIsUserLoggedOut } from '../../state/auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginGuard  {
  constructor(private navCtrl: NavController, private store: Store) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.store.select(selectIsUserLoggedOut).pipe(
      take(1),
      tap((isUserLoggedOut) => {
        if (!isUserLoggedOut) {
          // forward to the root page
          this.navCtrl.navigateRoot('', { replaceUrl: true });
        }
      })
    );
  }
}
