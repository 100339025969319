import { Component, NgZone } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private zone: NgZone,
    private navCtrl: NavController,
  ) {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => this.zone.run(() => {
      const url = new URL(event.url);
      this.navCtrl.navigateRoot(url.pathname);
    }));
  }
}
