import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { environment } from 'src/environments/environment';
import { ParseObject } from '../models/parse-object.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ParseService {
  constructor() {
    // initialize the parse api
    (Parse as any).serverURL = environment.back4App.serverUrl;
    Parse.initialize(environment.back4App.appId, environment.back4App.jsKey);
  }

  static serialize<T extends ParseObject = ParseObject>(parseObject: Parse.Object | null): T | null {
    if (parseObject === null) {
      return null;
    }

    if (typeof parseObject.toJSON === 'undefined') {
      return {} as T;
    }

    const jsonObject = parseObject.toJSON();
    ParseService.sanitizeAttributes(jsonObject);

    return jsonObject as T;
  }

  static deserialize(className: string, id: string, changes: Partial<ParseObject>): Parse.Object {
    const parseObject = new Parse.Object(className, changes);
    parseObject.id = id;
    return parseObject;
  }

  static async update<T extends Parse.Object>(parseObject: T, changes: object): Promise<T> {
    for (const key in changes) {
      if (!Object.prototype.hasOwnProperty.call(changes, key)) {
        continue;
      }

      const value = changes[key];

      let relation = new Parse.Relation();
      try {
        relation = parseObject.relation(key);
      } catch (err) {}

      if (relation.targetClassName) {
        // update related objects
        const relatedParseObjects: Parse.Object[] = await relation.query().find();
        // remove removed objects
        relation.remove(relatedParseObjects.filter(obj => !value.includes(obj.id)));
        // add new objects
        for (const newId of _.difference(value, _.map(relatedParseObjects, 'id'))) {
          const relatedParseObject = new Parse.Object(relation.targetClassName);
          relatedParseObject.id = newId;
          relation.add(relatedParseObject);
        }
      } else {
        // set the primitive attribute
        parseObject.set(key, value);
      }
    }

    return parseObject;
  }

  private static sanitizeAttributes(value): void {
    if (typeof value !== 'object' || value === null) {
      return;
    }

    if (value.objectId) {
      // rename objectId to id
      value.id = value.objectId;
      delete value.objectId;
    }

    for (const key in value) {
      if (!Object.prototype.hasOwnProperty.call(value, key)) {
        continue;
      }

      const property = value[key];
      // skip null property
      if (property === null) {
        continue;
      // remove type attribute from serialized geo point
      // eslint-disable-next-line no-underscore-dangle
      } else if (property.__type === 'GeoPoint') {
        // eslint-disable-next-line no-underscore-dangle
        delete property.__type;
      // sanitize serialized date
      // eslint-disable-next-line no-underscore-dangle
      } else if (property.__type === 'Date') {
        value[key] = property.iso;
      // TODO: sanitize pointer/object
      // eslint-disable-next-line no-underscore-dangle
      // } else if (['Pointer', 'Object'].includes(property.__type)) {
      //   value[key] = property.id;
      // delete relation
      // eslint-disable-next-line no-underscore-dangle
      } else if (property.__type === 'Relation') {
        delete value[key];
      }
      // recursively update the nested object
      ParseService.sanitizeAttributes(property);
    }
  }
}
