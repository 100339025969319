import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { Poo } from '../poo/poo.model';
import { Player, User } from '../user/user.model';
import { selectLoggedInUserId } from './auth/auth.selectors';
import { selectIsLoadingMap } from './map/map.selectors';
import {
  selectIsProcessingPoos,
  selectPoos,
  selectSelectedPooOwnerId,
} from './poo/poo.selectors';
import {
  selectSelectedUserId,
  selectUserEntities,
} from './user/user.selectors';

// logged in user selectors
export const selectLoggedInUser = createSelector(
  selectLoggedInUserId,
  selectUserEntities,
  (
    loggedInUserId: string | null,
    userEntities: Dictionary<User>
  ): User | null => userEntities[loggedInUserId] || null
);

export const selectLoggedInPlayer = createSelector(
  selectLoggedInUser,
  (loggedInUser: User | null): Player | null =>
    loggedInUser ? loggedInUser.player : null
);

export const selectTotalXP = createSelector(
  selectLoggedInPlayer,
  (loggedInPlayer: Player | null): number | null =>
    loggedInPlayer ? loggedInPlayer.totalXP : null
);

// current user means either the logged in user or the selected user
export const selectCurrentUserId = createSelector(
  selectSelectedUserId,
  selectLoggedInUserId,
  (
    selectedUserId: string | null,
    loggedInUserId: string | null
  ): string | null => selectedUserId || loggedInUserId || null
);

export const selectCurrentUser = createSelector(
  selectCurrentUserId,
  selectUserEntities,
  (currentUserId: string | null, userEntities: Dictionary<User>): User | null =>
    userEntities[currentUserId] || null
);

export const selectIsLoggedInUser = createSelector(
  selectCurrentUserId,
  selectLoggedInUserId,
  (currentUserId: string | null, loggedInUserId: string | null): boolean =>
    currentUserId && loggedInUserId && currentUserId === loggedInUserId
);

export const selectIsProcessingMap = createSelector(
  selectIsLoadingMap,
  selectIsProcessingPoos,
  (isLoadingMap: boolean, isProcessingPoos: boolean): boolean =>
    isLoadingMap || isProcessingPoos
);

export const selectPoosOfCurrentUser = createSelector(
  selectCurrentUserId,
  selectPoos,
  (currentUserId: string, poos: Poo[]): Poo[] =>
    poos.filter((poo) => poo.createdBy.id === currentUserId)
);

export const selectIsOwnedByLoggedInUser = createSelector(
  selectSelectedPooOwnerId,
  selectLoggedInUserId,
  (selectedPooOwnerId: string, loggedInUserId: string | null): boolean =>
    selectedPooOwnerId === loggedInUserId
);

export const selectSelectedPooOwner = createSelector(
  selectSelectedPooOwnerId,
  selectUserEntities,
  (selectedPooOwnerId: string, userEntities: Dictionary<User>): User =>
    userEntities[selectedPooOwnerId]
);
