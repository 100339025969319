import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { selectLogInSuccess } from '../../state/auth/auth.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, OnDestroy {
  private success$: Observable<boolean> = this.store
    .select(selectLogInSuccess)
    .pipe(filter((success) => success));

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private store: Store, private modalCtrl: ModalController) {}

  ngOnInit() {
    this.success$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.dismiss());
  }

  dismiss(): void {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
