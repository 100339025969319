import { Params } from '@angular/router';
import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { PooHelpers } from 'src/app/poo/poo.helpers';
import { Poo, Tag } from '../../poo/poo.model';
import { Error } from '../../shared/models/error.model';
import { CallState, LoadingState, getError } from '../helpers';
import { selectRouteParams } from '../router/router.selectors';
import { pooAdapter, pooFeature, pooTagAdapter } from './poo.reducer';

const pooSelectors = pooAdapter.getSelectors();

export const {
  selectPooState,
  selectIds,
  selectEntities,
  selectLoadPoosState,
  selectAddPooState,
  selectLoadPooState,
  selectUpdatePooState,
  // selectDeletedPooId,
  selectDeletePooState,
  selectTags,
} = pooFeature;

export const selectPoos = createSelector(
  selectPooState,
  pooSelectors.selectAll
);

export const selectPoosGeoJSON = createSelector(
  selectPoos,
  (poos: Poo[]): GeoJSON.FeatureCollection => PooHelpers.poos2GeoJson(poos)
);

export const selectIsLoadingPoos = createSelector(
  selectLoadPoosState,
  (callState: CallState): boolean => callState === LoadingState.loading
);

export const selectArePoosLoaded = createSelector(
  selectLoadPoosState,
  (callState: CallState): boolean => callState === LoadingState.loaded
);

export const selectLoadPoosError = createSelector(
  selectLoadPoosState,
  (callState: CallState): Error | null => getError(callState)
);

export const selectSelectedPooId = createSelector(
  selectRouteParams,
  (params: Params): string | undefined => params.pooId
);

export const selectSelectedPoo = createSelector(
  selectEntities,
  selectSelectedPooId,
  (entities: Dictionary<Poo>, id: string): Poo | undefined => entities[id]
);

export const selectSelectedPooOwnerId = createSelector(
  selectSelectedPoo,
  (poo: Poo | undefined): string | undefined => poo?.createdBy.id
);

export const selectSelectedPooTags = createSelector(
  selectSelectedPoo,
  (poo: Poo | undefined): Tag[] | undefined => poo?.tags
);

export const selectIsAddingPoo = createSelector(
  selectAddPooState,
  (callState: CallState): boolean => callState === LoadingState.loading
);

export const selectAddPooSuccess = createSelector(
  selectAddPooState,
  (callState: CallState): boolean => callState === LoadingState.loaded
);

export const selectAddPooError = createSelector(
  selectAddPooState,
  (callState: CallState): Error | null => getError(callState)
);

export const selectIsLoadingPoo = createSelector(
  selectLoadPooState,
  (callState: CallState): boolean => callState === LoadingState.loading
);

export const selectIsUpdatingPoo = createSelector(
  selectUpdatePooState,
  (callState: CallState): boolean => callState === LoadingState.loading
);

export const selectUpdatePooError = createSelector(
  selectUpdatePooState,
  (callState: CallState): Error | null => getError(callState)
);

export const selectIsDeletingPoo = createSelector(
  selectDeletePooState,
  (callState: CallState): boolean => callState === LoadingState.loading
);

export const selectIsPooDeleted = createSelector(
  selectDeletePooState,
  (callState: CallState): boolean => callState === LoadingState.loaded
);

export const selectDeletePooError = createSelector(
  selectDeletePooState,
  (callState: CallState): Error | null => getError(callState)
);

export const selectIsProcessingPoos = createSelector(
  selectIsLoadingPoos,
  selectIsAddingPoo,
  selectIsDeletingPoo,
  (
    isLoadingPoos: boolean,
    isAddingPoo: boolean,
    isDeletingPoo: boolean
  ): boolean => isLoadingPoos || isAddingPoo || isDeletingPoo
);

// export const selectPoosOfUser = createSelector(
//   selectPoos,
//   selectSelectedUserId,
//   (poos: Poo[], userId: string): Poo[] => poos.filter(poo => poo.createdBy.id === userId)
// );

// export const selectPoosOfCurrentUser = createSelector(
//   selectPoos,
//   selectCurrentUserId,
//   (poos: Poo[], userId: string): Poo[] => poos.filter(poo => poo.createdBy.id === userId)
// );

const pooTagSelectors = pooTagAdapter.getSelectors();

export const selectAllTags = createSelector(
  selectTags,
  pooTagSelectors.selectAll
);
