/* eslint-disable @typescript-eslint/naming-convention */
import { Update } from '@ngrx/entity';
import { createActionGroup, props } from '@ngrx/store';
import { Poo, Tag } from '../../poo/poo.model';
import { Error } from '../../shared/models/error.model';

export const PooActions = createActionGroup({
  source: 'Poo API',
  events: {
    'Load Poos Success': props<{ poos: Poo[] }>(),
    'Load Poos Failure': props<{ error: Error }>(),
    'Add Poo Success': props<{ poo: Poo }>(),
    'Add Poo Failure': props<{ error: Error }>(),
    'Add Poo Live': props<{ poo: Poo }>(),
    'Load Poo Success': props<{ poo: Poo }>(),
    'Load Poo Failure': props<{ error: Error }>(),
    'Update Poo Success': props<{ poo: Poo }>(),
    'Update Poo Failure': props<{ error: Error }>(),
    'Delete Poo Success': props<{ poo: Poo }>(),
    'Delete Poo Failure': props<{ error: Error }>(),
    'Delete Poo Live': props<{ poo: Poo }>(),
    'Restore Poo': props<{ id: string }>(),
    'Load Poos Of User Success': props<{ poos: Poo[] }>(),
    'Load Poos Of User Failure': props<{ error: Error }>(),
    'Load All Tags Success': props<{ tags: Tag[] }>(),
    'Load All Tags Failure': props<{ error: Error }>(),
    'Load Poo Tags Success': props<{ update: Update<Poo> }>(),
    'Load Poo Tags Failure': props<{ error: Error }>(),
  },
});
