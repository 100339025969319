import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertController, ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import * as Parse from 'parse';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import {
  logIn,
  logInFailure,
  requestEmailVerification,
  requestPasswordReset,
} from 'src/app/state/auth/auth.actions';
import {
  selectIsLoggingIn,
  selectLogInError,
} from 'src/app/state/auth/auth.selectors';
import { PASSWORD_MIN_LENGTH } from 'src/app/user/user.model';
import { Error } from '../../../shared/models/error.model';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Output() signUp: EventEmitter<void> = new EventEmitter<void>();

  credentials: UntypedFormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: [
      '',
      [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)],
    ],
  });

  isLoggingIn$: Observable<boolean> = this.store.select(selectIsLoggingIn);

  private error$: Observable<Error> = this.store
    .select(selectLogInError)
    .pipe(filter((err) => !!err));

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController
  ) {}

  get email() {
    return this.credentials.get('email');
  }

  get password() {
    return this.credentials.get('password');
  }

  ngOnInit() {
    this.error$
      .pipe(takeUntil(this.destroy$))
      .subscribe((error) => this.onLogInError(error));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logIn() {
    this.store.dispatch(logIn({ credentials: this.credentials.value }));
  }

  resetPassword() {
    this.email.markAsTouched();

    if (!this.email.valid) {
      return;
    }

    const email: string = this.email.value;
    this.alertCtrl
      .create({
        header: 'Reset Password',
        message: `We will send an email to ${email}`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'OK',
            handler: () => this.store.dispatch(requestPasswordReset({ email })),
          },
        ],
      })
      .then((alert) => alert.present());
  }

  private onLogInError(err: Error): void {
    console.log('log in error');
    const buttons = [];
    let color = 'danger';
    if (err.code === Parse.Error.EMAIL_NOT_FOUND) {
      buttons.push({
        text: 'Resend',
        handler: () =>
          this.store.dispatch(
            requestEmailVerification({ email: this.email.value })
          ),
      });
      color = undefined;
    }
    this.toastCtrl
      .create({
        message: err.message,
        duration: 2000,
        buttons,
        color,
      })
      .then((toast) => toast.present());
    this.store.dispatch(logInFailure(null));
  }
}
