import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { MapPageActions } from 'src/app/map/map.page.actions';
import { PooEditComponentActions } from 'src/app/poo/poo-edit/poo-edit.component.actions';
import { PooComponentActions } from 'src/app/poo/poo.component.actions';
import { Poo, Tag } from '../../poo/poo.model';
import { CallState, LoadingState } from '../helpers';
import { MapActions } from '../map/map.actions';
import { PooActions } from './poo.actions';

export const pooFeatureKey = 'poo';

export interface PooState extends EntityState<Poo> {
  loadPoosState: CallState;

  addPooState: CallState;

  loadPooState: CallState;

  updatePooState: CallState;

  // deletedPooId: string | null;
  deletePooState: CallState;

  tags: EntityState<Tag>;
}

export const pooAdapter: EntityAdapter<Poo> = createEntityAdapter<Poo>({
  // sort descending by date of creation
  sortComparer: (a, b) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
});

export const pooTagAdapter: EntityAdapter<Tag> = createEntityAdapter<Tag>();

export const initialState: PooState = pooAdapter.getInitialState({
  loadPoosState: LoadingState.init,

  addPooState: LoadingState.init,

  loadPooState: LoadingState.init,

  updatePooState: LoadingState.init,

  deletedPooId: null,
  deletePooState: LoadingState.init,

  tags: pooTagAdapter.getInitialState(),
});

export const reducer = createReducer(
  initialState,

  // load poos
  on(
    MapActions.loadNearPoos,
    (state): PooState => ({ ...state, loadPoosState: LoadingState.loading })
  ),
  on(
    PooActions.loadPoosSuccess,
    (state, { poos }): PooState =>
      pooAdapter.upsertMany(poos, {
        ...state,
        loadPoosState: LoadingState.loaded,
      })
  ),
  on(
    PooActions.loadPoosFailure,
    (state, { error }): PooState => ({ ...state, loadPoosState: { error } })
  ),

  // add poo
  on(
    MapPageActions.addPoo,
    (state, { coordinates }): PooState => ({
      ...state,
      addPooState: LoadingState.loading,
    })
  ),
  on(
    PooActions.addPooSuccess,
    (state, { poo }): PooState =>
      pooAdapter.addOne(poo, { ...state, addPooState: LoadingState.loaded })
  ),
  on(
    PooActions.addPooLive,
    (state, { poo }): PooState => pooAdapter.addOne(poo, state)
  ),
  on(
    PooActions.addPooFailure,
    (state, { error }): PooState => ({ ...state, addPooState: { error } })
  ),

  // load poo
  on(
    PooComponentActions.loadPoo,
    PooEditComponentActions.loadPoo,
    (state, { id }): PooState => ({
      ...state,
      loadPooState: LoadingState.loading,
    })
  ),
  on(
    PooActions.loadPooSuccess,
    (state, { poo }): PooState =>
      pooAdapter.upsertOne(poo, { ...state, loadPooState: LoadingState.loaded })
  ),
  on(
    PooActions.loadPooFailure,
    (state, { error }): PooState => ({ ...state, loadPooState: { error } })
  ),

  // update poo
  on(
    PooEditComponentActions.updatePoo,
    (state, { update }): PooState => ({
      ...state,
      updatePooState: LoadingState.loading,
    })
  ),
  on(
    PooActions.updatePooSuccess,
    (state, { poo }): PooState =>
      pooAdapter.upsertOne(poo, {
        ...state,
        updatePooState: LoadingState.loaded,
      })
  ),
  on(
    PooActions.updatePooFailure,
    (state, { error }): PooState => ({ ...state, updatePooState: { error } })
  ),

  // delete poo
  on(
    PooComponentActions.deletePoo,
    (state): PooState => ({ ...state, deletePooState: LoadingState.loading })
  ),
  on(
    PooActions.deletePooSuccess,
    (state, { poo }): PooState =>
      pooAdapter.removeOne(poo.id, {
        ...state,
        deletePooState: LoadingState.init,
      })
  ),
  on(
    PooActions.deletePooLive,
    (state, { poo }): PooState => pooAdapter.removeOne(poo.id, state)
  ),
  on(
    PooActions.deletePooFailure,
    (state, { error }): PooState => ({ ...state, deletePooState: { error } })
  ),

  // TODO: restore poo

  // poos of user
  on(PooActions.loadPoosOfUserSuccess, (state, { poos }) =>
    pooAdapter.upsertMany(poos, state)
  ),

  // tags
  on(PooActions.loadAllTagsSuccess, (state, { tags }) => ({
    ...state,
    tags: pooTagAdapter.upsertMany(tags, state.tags),
  })),

  // poo tags
  on(PooActions.loadPooTagsSuccess, (state, { update }) =>
    pooAdapter.updateOne(update, state)
  )
);

export const pooFeature = createFeature({
  name: pooFeatureKey,
  reducer,
});
