import { ToastController, ToastOptions } from '@ionic/angular';
import { Error } from '../shared/models/error.model';

export enum LoadingState {
  init,
  loading,
  loaded,
}

export interface ErrorState {
  error: Error;
}

export type CallState = LoadingState | ErrorState;

// Helper function to extract error, if there is one.
export const getError = (callState: CallState): Error | null => {
  if ((callState as ErrorState).error !== undefined) {
    return (callState as ErrorState).error;
  }
  return null;
};

export const showToast = (
  toastCtrl: ToastController,
  options: ToastOptions = {}
): void => {
  const optionsWithDefaults = Object.assign(
    {
      duration: 2000,
      // position: 'middle',
    },
    options
  );
  toastCtrl.create(optionsWithDefaults).then((toast) => toast.present());
};

export const showErrorToast = (
  toastCtrl: ToastController,
  error: Error,
  options: ToastOptions = {}
): void => {
  if (error !== null) {
    showToast(toastCtrl, { message: error.message });
  }
};
