import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ParseService } from '../shared/services/parse.service';
import { Credentials, User } from '../user/user.model';
import { UserParse } from '../user/user.parse';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject$: BehaviorSubject<UserParse> =
    new BehaviorSubject<UserParse>(null);

  constructor(private parseService: ParseService) {
    this.getCurrentUser().subscribe();
  }

  get user$(): Observable<User> {
    return this.userSubject$
      .asObservable()
      .pipe(map((userParse) => ParseService.serialize<User>(userParse)));
  }

  logIn(credentials: Credentials): Observable<User> {
    return from(
      UserParse.logIn<UserParse>(credentials.email, credentials.password)
    ).pipe(
      // // fetch the user class including the player
      // fetchPlayer(),
      // update the user subject with the the logged in user
      tap((userParse) => this.userSubject$.next(userParse)),
      map((userParse) => ParseService.serialize<User>(userParse)),
      tap((user) => console.log('Logged in successfully', user))
    );
  }

  logOut(): Observable<User> {
    return from(UserParse.logOut<UserParse>()).pipe(
      // remove the logged in user from the user subject
      tap(() => this.userSubject$.next(null)),
      map((userParse) => ParseService.serialize<User>(userParse)),
      tap((user) => console.log('Logged out successfully', user))
    );
  }

  signUp(credentials: Credentials, username: string): Observable<User> {
    return from(
      UserParse.signUp<UserParse>(username, credentials.password, {
        email: credentials.email,
      })
    ).pipe(
      map((userParse) => ParseService.serialize<User>(userParse)),
      // log for debug purposes
      tap((user) => console.log('Signed up successfully', user))
    );
  }

  requestEmailVerification(email: string): Observable<User> {
    return from(UserParse.requestEmailVerification<UserParse>(email)).pipe(
      map((userParse) => ParseService.serialize<User>(userParse)),
      tap((user) => console.log('Email verification requested', user))
    );
  }

  requestPasswordReset(email: string): Observable<User> {
    return from(UserParse.requestPasswordReset<UserParse>(email)).pipe(
      map((userParse) => ParseService.serialize<User>(userParse)),
      tap((user) => console.log('Password reset requested', user))
    );
  }

  private getCurrentUser(): Observable<User | null> {
    return from(UserParse.currentAsync<UserParse>()).pipe(
      // // fetch the user class including the player
      // fetchPlayer(),
      // update the user subject with the the current user
      tap((userParse) => this.userSubject$.next(userParse)),
      map((userParse) => ParseService.serialize<User>(userParse)),
      tap((user) => console.log('Current user', user))
    );
  }
}
