import { Injectable } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MenuPageActions } from 'src/app/menu/menu.page.actions';
import { AuthService } from '../../auth/auth.service';
import {
  logIn,
  logInFailure,
  logInSuccess,
  logOut,
  logOutSuccess,
  requestEmailVerification,
  requestPasswordReset,
  signUp,
  signUpFailure,
  signUpSuccess,
  userUpdated,
} from './auth.actions';

@Injectable()
export class AuthEffects {
  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUp),
      switchMap(({ credentials, username }) =>
        this.authService.signUp(credentials, username).pipe(
          map((user) => signUpSuccess({ user })),
          catchError((err) =>
            of(signUpFailure({ error: Object.assign({}, err) }))
          )
        )
      )
    )
  );

  signUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signUpSuccess),
        tap(({ user }) => this.navCtrl.navigateForward('/login'))
      ),
    {
      dispatch: false,
    }
  );

  requestEmailVerification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(requestEmailVerification),
        switchMap(({ email }) =>
          this.authService
            .requestEmailVerification(email)
            .pipe
            // map(user => requestEmailVerificationSuccess({ user })),
            // catchError(err => of(requestEmailVerificationError({ err }))),
            ()
        )
      ),
    {
      dispatch: false,
    }
  );

  logIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logIn),
      switchMap(({ credentials }) =>
        this.authService.logIn(credentials).pipe(
          map((user) => logInSuccess({ user })),
          catchError((err) =>
            of(logInFailure({ error: Object.assign({}, err) }))
          )
        )
      )
    )
  );

  logInSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logInSuccess),
        tap(({ user }) => {
          this.navCtrl.navigateRoot('', { replaceUrl: true });
          this.toastCtrl
            .create({
              message: `User ${user.username} logged in successfully`,
              duration: 2000,
              color: 'success',
            })
            .then((toast) => toast.present());
        })
      ),
    {
      dispatch: false,
    }
  );

  logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuPageActions.logOut, logOut),
      switchMap(() =>
        this.authService.logOut().pipe(
          map(() => logOutSuccess())
          // catchError(err => of(logOutError({ err }))),
        )
      )
    )
  );

  logOutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logOutSuccess),
        tap(() => {
          this.navCtrl.navigateRoot('', { replaceUrl: true });
          this.toastCtrl
            .create({
              message: `Logged out successfully`,
              duration: 2000,
              color: 'success',
            })
            .then((toast) => toast.present());
        })
      ),
    {
      dispatch: false,
    }
  );

  requestPasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(requestPasswordReset),
        switchMap(({ email }) =>
          this.authService
            .requestPasswordReset(email)
            .pipe
            // map(user => requestPasswordResetSuccess({ user })),
            // catchError(err => of(requestPasswordResetError({ err }))),
            ()
        )
      ),
    {
      dispatch: false,
    }
  );

  userUpdated$ = createEffect(() =>
    this.authService.user$.pipe(map((user) => userUpdated({ user })))
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private navCtrl: NavController,
    private toastCtrl: ToastController
  ) {}
}
