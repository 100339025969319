import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { AuthStateModule } from './auth/auth-state.module';
import { MapStateModule } from './map/map-state.module';
import { PooStateModule } from './poo/poo-state.module';
import { PositionStateModule } from './position/position-state.module';
import { metaReducers, reducers } from './reducers';
import { UserStateModule } from './user/user-state.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthStateModule,
    MapStateModule,
    PositionStateModule,
    PooStateModule,
    UserStateModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionSerializability: true,
        strictActionImmutability: true,
        // strictActionWithinNgZone: true,
        strictStateSerializability: true,
        strictStateImmutability: true,
      },
    }),
    // for debugging redux actions
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    // Connects RouterModule with StoreModule, uses MinimalRouterStateSerializer by default
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(),
  ],
})
export class StateModule {}
