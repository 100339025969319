/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Coordinates } from './map.model';

export const MapPageActions = createActionGroup({
  source: 'Map Page',
  events: {
    Init: emptyProps(),
    'Load Map Success': emptyProps(),
    'Resize Map': emptyProps(),
    'Watch Position': emptyProps(),
    'Follow Position': emptyProps(),
    'Unfollow Position': emptyProps(),
    'Follow Orientation': emptyProps(),
    'Unfollow Orientation': emptyProps(),
    'Set Center': props<{ center: Coordinates }>(),
    'Reset North': emptyProps(),
    'Toggle Adding Marker': emptyProps(),
    'Toggle Stale Poos': emptyProps(),
    'Add Poo': props<{ coordinates: Coordinates }>(),
  },
});
