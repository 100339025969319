import { Position } from '@capacitor/geolocation';
import { createFeature, createReducer, on } from '@ngrx/store';
import { MapPageActions } from 'src/app/map/map.page.actions';
import { CallState, LoadingState } from '../helpers';
import { PositionActions } from './position.actions';

export const positionFeatureKey = 'position';

export interface PositionState {
  position: Position | null;
  alpha: number;
  watchPositionState: CallState;
  startedFollowingPosition: boolean;
}

export const initialState: PositionState = {
  position: null,
  alpha: 0,
  watchPositionState: LoadingState.init,
  startedFollowingPosition: false,
};

export const reducer = createReducer(
  initialState,

  // on(
  //   PositionActions.setPositionPermissionSuccess,
  //   (state, { positionPermission }): PositionState => ({
  //     ...state,
  //     permission: positionPermission,
  //   })
  // ),

  on(
    MapPageActions.watchPosition,
    PositionActions.watchPosition,
    (state): PositionState => ({
      ...state,
      watchPositionState: LoadingState.loading,
    })
  ),
  on(
    PositionActions.watchPositionError,
    (state, { error }): PositionState => ({
      ...state,
      // watchPositionState: { error },
      watchPositionState: LoadingState.init,
    })
  ),

  on(
    PositionActions.setPositionSuccess,
    (state, { position }): PositionState => ({
      ...state,
      position,
      watchPositionState: LoadingState.loaded,
    })
  ),
  // on(
  //   PositionActions.setPositionFailure,
  //   (state, { error }): PositionState => ({
  //     ...state,
  //     state: { error },
  //   })
  // )

  on(
    PositionActions.followPosition,
    (state): PositionState => ({ ...state, startedFollowingPosition: true })
  ),

  on(
    PositionActions.setOrientationSuccess,
    (state, { alpha }): PositionState => ({
      ...state,
      alpha,
    })
  )
);

export const positionFeature = createFeature({
  name: positionFeatureKey,
  reducer,
});
