import { createSelector } from '@ngrx/store';
import { Error } from '../../shared/models/error.model';
import { CallState, getError, LoadingState } from '../helpers';
import { authFeature } from './auth.reducer';

export const {
  selectAuthState,
  selectLoggedInUserId,
  selectSignUpState,
  selectLogInState,
} = authFeature;

export const selectIsUserLoggedIn = createSelector(
  selectLoggedInUserId,
  (id: string | null): boolean => id !== null // && user.emailVerified
);

export const selectIsUserLoggedOut = createSelector(
  selectLoggedInUserId,
  (id: string | null): boolean => id === null
);

export const selectIsSigningUp = createSelector(
  selectSignUpState,
  (signUpState: CallState): boolean => signUpState === LoadingState.loading
);

export const selectSignUpSuccess = createSelector(
  selectSignUpState,
  (signUpState: CallState): boolean => signUpState === LoadingState.loaded
);

export const selectSignUpError = createSelector(
  selectSignUpState,
  (signUpState: CallState): Error | null => getError(signUpState)
);

export const selectIsLoggingIn = createSelector(
  selectLogInState,
  (logInState: CallState): boolean => logInState === LoadingState.loading
);

export const selectLogInSuccess = createSelector(
  selectLogInState,
  (logInState: CallState): boolean => logInState === LoadingState.loaded
);

export const selectLogInError = createSelector(
  selectLogInState,
  (logInState: CallState): Error | null => getError(logInState)
);
