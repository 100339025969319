/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Coordinates, View } from 'src/app/map/map.model';

export const MapActions = createActionGroup({
  source: 'Map API',
  events: {
    'Follow Position Success': props<{ coords: Coordinates }>(),
    'Follow Orientation Success': props<{ bearing: number }>(),
    'Ease To View': props<{ view: View }>(),
    'Clear Ease To View': emptyProps(),
    'Load Near Poos': props<{ coordinates: Coordinates; filter?: string[] }>(),
  },
});
