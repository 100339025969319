import { Params } from '@angular/router';
import { createSelector } from '@ngrx/store';
import * as mapboxgl from 'mapbox-gl';
import { View, stalePoosLayerId } from 'src/app/map/map.model';
import { CallState, LoadingState } from '../helpers';
import { selectRouteParams } from '../router/router.selectors';
import { mapFeature } from './map.reducer';

export const {
  selectMapState,
  selectLoadMapState,
  selectCenter,
  selectEaseToView,
  // selectLayers,
  selectVisibleLayerIds,
  selectIsFollowingPosition,
  selectIsFollowingOrientation,
  selectAreMarkersRendered,
  selectIsAddingMarker,
} = mapFeature;

export const selectIsLoadingMap = createSelector(
  selectLoadMapState,
  (callState: CallState): boolean => callState === LoadingState.loading
);

export const selectIsMapLoaded = createSelector(
  selectLoadMapState,
  (callState: CallState): boolean => callState === LoadingState.loaded
);

export const selectEaseToOptions = createSelector(
  selectEaseToView,
  (view: View | null): mapboxgl.EaseToOptions | null => {
    if (view === null) {
      return null;
    }
    const { center, ...viewWithoutCenter } = view;
    return {
      ...viewWithoutCenter,
      ...(center && { center: [center.longitude, center.latitude] }),
    };
  }
);

export const selectFollowPositionStatus = createSelector(
  selectIsFollowingPosition,
  selectIsFollowingOrientation,
  (isFollowingPosition, isFollowingOrientation) => ({
    isFollowingPosition,
    isFollowingOrientation,
  })
);

export const selectIsModalOpen = createSelector(
  selectRouteParams,
  (params: Params | null): boolean => params && Object.keys(params).length !== 0
);

// const mapLayerSelectors = mapLayerAdapter.getSelectors();

// export const selectAllLayers = createSelector(
//   selectLayers,
//   mapLayerSelectors.selectAll
// );

export const selectIsStalePoosLayerVisible = createSelector(
  selectVisibleLayerIds,
  (visibleLayerIds: string[]): boolean =>
    visibleLayerIds.includes(stalePoosLayerId)
);
