import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Error } from '../../shared/models/error.model';
import { signUp, signUpFailure } from '../../state/auth/auth.actions';
import { selectSignUpError } from '../../state/auth/auth.selectors';
import { passwordsMatchValidator } from './shared/passwords-match.directive';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.page.html',
  styleUrls: ['./sign-up.page.scss'],
})
export class SignUpPage implements OnInit, OnDestroy {
  minUsernameLength = 2;
  minPasswordLength = 5;

  userForm: UntypedFormGroup;

  private destroy$: Subject<void> = new Subject<void>();

  private err$: Observable<Error> = this.store.select(selectSignUpError).pipe(
    filter((err) => !!err),
    takeUntil(this.destroy$)
  );

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private toastCtrl: ToastController
  ) {}

  get email() {
    return this.userForm.get('email');
  }

  get username() {
    return this.userForm.get('username');
  }

  get password() {
    return this.userForm.get('password');
  }

  get confirmPassword() {
    return this.userForm.get('confirmPassword');
  }

  ngOnInit() {
    this.userForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        username: [
          '',
          [Validators.required, Validators.minLength(this.minUsernameLength)],
        ],
        password: [
          '',
          [Validators.required, Validators.minLength(this.minPasswordLength)],
        ],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: passwordsMatchValidator,
      }
    );

    this.err$.subscribe((err) => this.onSignUpError(err));
  }

  signUp() {
    this.store.dispatch(
      signUp({
        credentials: {
          email: this.email.value,
          password: this.password.value,
        },
        username: this.username.value,
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private onSignUpError(err: Error): void {
    this.toastCtrl
      .create({
        message: err.message,
        duration: 2000,
        color: 'danger',
      })
      .then((toast) => toast.present());
    this.store.dispatch(signUpFailure(null));
  }
}
