/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const MenuPageActions = createActionGroup({
  source: 'Menu Page',
  events: {
    'Load User': props<{ id: string }>(),
    'Load Poos of User': props<{ id: string }>(),
    'Log Out': emptyProps(),
  },
});
