import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, on } from '@ngrx/store';
import { User } from '../../user/user.model';
import { CallState, LoadingState } from '../helpers';
import * as userActions from './user.actions';

export const userFeatureKey = 'user';

export interface UserState extends EntityState<User> {
  loadUserState: CallState;
  updateUserState: CallState;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialState: UserState = adapter.getInitialState({
  loadUserState: LoadingState.init,
  updateUserState: LoadingState.init,
});

export const reducer = createReducer(
  initialState,

  on(
    userActions.loadUser,
    userActions.updateUser,
    (state): UserState => ({
      ...state,
      loadUserState: LoadingState.loading,
    })
  ),
  on(
    userActions.loadUserSuccess,
    (state, { user }): UserState =>
      adapter.upsertOne(user, { ...state, loadUserState: LoadingState.loaded })
  ),
  on(
    userActions.loadUserFailure,
    (state, { error }): UserState => ({ ...state, loadUserState: { error } })
  ),

  on(
    userActions.updateUserSuccess,
    (state, { user }): UserState =>
      adapter.upsertOne(user, {
        ...state,
        updateUserState: LoadingState.loaded,
      })
  ),
  on(
    userActions.updateUserFailure,
    (state, { error }): UserState => ({ ...state, updateUserState: { error } })
  ),

  on(
    userActions.loadPlayerSuccess,
    (state, { player }): UserState =>
      adapter.updateOne({ id: player.user.id, changes: { player } }, state)
  ),
  on(
    userActions.loadPlayerFailure,
    (state, { error }): UserState => ({ ...state, loadUserState: { error } })
  )
);

export const userFeature = createFeature({
  name: userFeatureKey,
  reducer,
});
